import React from "react";
import { useParams, useHistory } from "react-router-dom";
import Event1 from "./Event1";
import Event2 from "./Event2";
import Event3 from "./Event3";
import Event4 from "./Event4";
import Event5 from "./Event5";
import Event6 from "./Event6";
import Event7 from "./Event7";
import Event8 from "./Event8";
import Event9 from "./Event9";
import Event10 from "./Event10";
const EventsWrapper = () => {
  const params = useParams();

  const { id } = params;

  if (id == 1) {
    return <Event1 />;
  } else if (id == 2) {
    return <Event2 />;
  } else if (id == 3) {
    return <Event3 />;
  } else if (id == 4) {
    return <Event4 />;
  } else if (id == 5) {
    return <Event5 />;
  } else if (id == 6) {
    return <Event6 />;
  } else if (id == 7) {
    return <Event7 />;
  } else if (id == 8) {
    return <Event8 />;
  } else if (id == 9) {
    return <Event9 />;
  } else if (id == 10) {
    return <Event10 />;
  }

  return <div>No News Found With this ID</div>;
};

export default EventsWrapper;
