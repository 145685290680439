import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { workshopSeminarData } from "../../data/workshop-seminar/WorkshopSeminarData";
import { Col, Container, Row, Card, Modal } from "react-bootstrap";
import Footer from "../../components/Footer";
import axios from "axios";
import moment from "moment";
import { BASE_API_URL, BASE_DOC_URL } from "../../actions";

const fullScreenModalStyle = {
  modal: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    zIndex: 9999,
    display: "flex",
    flexDirection: "column",
    margin: 0,
  },
  header: {
    padding: "1rem",
    color: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "rgba(0, 0, 0, 0.5)",
  },
  closeButton: {
    background: "none",
    border: "none",
    color: "white",
    fontSize: "1.5rem",
    cursor: "pointer",
    padding: "0.5rem",
    transition: "transform 0.2s",
  },
  body: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "2rem",
    position: "relative",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    position: "relative",
  },
  image: {
    maxHeight: "80vh",
    maxWidth: "90%",
    objectFit: "contain",
    cursor: "pointer",
    transition: "transform 0.3s ease",
  },
  navigation: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "0 2rem",
  },
  navButton: {
    background: "rgba(0, 0, 0, 0.5)",
    border: "none",
    color: "white",
    padding: "1rem",
    cursor: "pointer",
    borderRadius: "50%",
    transition: "background-color 0.3s",
  },
  imageInfo: {
    color: "white",
    padding: "1rem",
    background: "rgba(0, 0, 0, 0.5)",
    borderRadius: "8px",
    marginTop: "1rem",
  },
};

const albumModalStyle = {
  modal: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    zIndex: 9999,
    display: "flex",
    flexDirection: "column",
    margin: 0,
    overflow: "hidden",
  },
  header: {
    padding: "1.5rem",
    color: "white",
    background: "rgba(0, 0, 0, 0.5)",
    borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
  },
  title: {
    margin: 0,
    fontSize: "2rem",
    color: "white",
  },
  subtitle: {
    color: "rgba(255, 255, 255, 0.7)",
    fontSize: "1.1rem",
    marginTop: "0.5rem",
  },
  closeButton: {
    position: "absolute",
    right: "1.5rem",
    top: "1.5rem",
    background: "none",
    border: "none",
    color: "white",
    fontSize: "2rem",
    cursor: "pointer",
    padding: "0.5rem",
    transition: "transform 0.2s",
    zIndex: 1,
  },
  body: {
    flex: 1,
    padding: "2rem",
    overflowY: "auto",
    color: "white",
  },
  metadata: {
    marginBottom: "2rem",
    padding: "1rem",
    background: "rgba(255, 255, 255, 0.1)",
    borderRadius: "8px",
  },
  imageGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
    gap: "1.5rem",
    padding: "1rem",
  },
  imageCard: {
    position: "relative",
    borderRadius: "8px",
    overflow: "hidden",
    cursor: "pointer",
    transition: "transform 0.3s ease",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
  image: {
    width: "100%",
    aspectRatio: "1/1",
    objectFit: "cover",
  },
  imageTitle: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    padding: "0.75rem",
    background: "rgba(0, 0, 0, 0.7)",
    color: "white",
    transition: "opacity 0.3s ease",
  },
};

const WorkshopSeminar = () => {
  const [albums, setAlbums] = useState([]);
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const [showAlbumModal, setShowAlbumModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    fetchAlbums();
  }, []);

  const fetchAlbums = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}/seminars`);
      if (response.data.statusCode === 200) {
        setAlbums(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching albums:", error);
    }
  };

  const handleAlbumClick = (album) => {
    setSelectedAlbum(album);
    setShowAlbumModal(true);
  };

  const handleNavigateImage = (direction) => {
    if (!selectedAlbum?.images) return;

    let newIndex = currentImageIndex + direction;
    if (newIndex < 0) {
      newIndex = selectedAlbum.images.length - 1;
    } else if (newIndex >= selectedAlbum.images.length) {
      newIndex = 0;
    }
    setCurrentImageIndex(newIndex);
    setSelectedImage(selectedAlbum.images[newIndex]);
  };

  const handleImageClick = (image, index) => {
    setSelectedImage(image);
    setCurrentImageIndex(index);
    setShowImageModal(true);
  };

  const renderFullScreenModal = () => {
    if (!showImageModal) return null;

    return (
      <div style={fullScreenModalStyle.modal}>
        <div style={fullScreenModalStyle.header}>
          <h4 style={{ margin: 0 }}>{selectedImage?.title}</h4>
          <button
            style={fullScreenModalStyle.closeButton}
            onClick={() => setShowImageModal(false)}
            onMouseOver={(e) => (e.target.style.transform = "scale(1.1)")}
            onMouseOut={(e) => (e.target.style.transform = "scale(1)")}
          >
            ×
          </button>
        </div>

        <div style={fullScreenModalStyle.body}>
          <div style={fullScreenModalStyle.imageContainer}>
            <img
              src={
                selectedImage
                  ? `${BASE_DOC_URL}/${selectedImage.image_path}`
                  : ""
              }
              alt={selectedImage?.title || "Gallery Image"}
              style={fullScreenModalStyle.image}
              onMouseOver={(e) => (e.target.style.transform = "scale(1.02)")}
              onMouseOut={(e) => (e.target.style.transform = "scale(1)")}
            />

            {selectedAlbum?.images?.length > 1 && (
              <div style={fullScreenModalStyle.navigation}>
                <button
                  style={fullScreenModalStyle.navButton}
                  onClick={() => handleNavigateImage(-1)}
                  onMouseOver={(e) =>
                    (e.target.style.backgroundColor = "rgba(0, 0, 0, 0.8)")
                  }
                  onMouseOut={(e) =>
                    (e.target.style.backgroundColor = "rgba(0, 0, 0, 0.5)")
                  }
                >
                  ‹
                </button>
                <button
                  style={fullScreenModalStyle.navButton}
                  onClick={() => handleNavigateImage(1)}
                  onMouseOver={(e) =>
                    (e.target.style.backgroundColor = "rgba(0, 0, 0, 0.8)")
                  }
                  onMouseOut={(e) =>
                    (e.target.style.backgroundColor = "rgba(0, 0, 0, 0.5)")
                  }
                >
                  ›
                </button>
              </div>
            )}
          </div>

          {(selectedImage?.description || selectedImage?.capture_time) && (
            <div style={fullScreenModalStyle.imageInfo}>
              {selectedImage.description && (
                <p style={{ margin: "0 0 0.5rem 0" }}>
                  {selectedImage.description}
                </p>
              )}
              {selectedImage.capture_time && (
                <small className="text-muted">
                  Captured on:{" "}
                  {moment(selectedImage.capture_time).format("MMMM DD, YYYY")}
                </small>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderAlbumModal = () => {
    if (!showAlbumModal) return null;

    return (
      <div style={albumModalStyle.modal}>
        <button
          style={albumModalStyle.closeButton}
          onClick={() => setShowAlbumModal(false)}
          onMouseOver={(e) => (e.target.style.transform = "scale(1.1)")}
          onMouseOut={(e) => (e.target.style.transform = "scale(1)")}
        >
          ×
        </button>

        <div style={albumModalStyle.header}>
          <h2 style={albumModalStyle.title}>{selectedAlbum?.title}</h2>
          {selectedAlbum?.subtitle && (
            <div style={albumModalStyle.subtitle}>{selectedAlbum.subtitle}</div>
          )}
        </div>

        <div style={albumModalStyle.body}>
          <div style={albumModalStyle.metadata}>
            <p style={{ margin: "0 0 0.5rem 0" }}>
              <small>
                {selectedAlbum?.seminarDate &&
                  moment(selectedAlbum.seminarDate).format("MMMM DD, YYYY")}
                {selectedAlbum?.location && <> • {selectedAlbum.location}</>}
              </small>
            </p>
            {selectedAlbum?.description && (
              <p style={{ margin: 0 }}>{selectedAlbum.description}</p>
            )}
          </div>

          <div style={albumModalStyle.imageGrid}>
            {selectedAlbum?.images?.map((image, index) => (
              <div
                key={image.id}
                style={albumModalStyle.imageCard}
                onClick={() => handleImageClick(image, index)}
                onMouseOver={(e) =>
                  (e.currentTarget.style.transform = "scale(1.02)")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.transform = "scale(1)")
                }
              >
                <img
                  src={`${BASE_DOC_URL}/${image.image_path}`}
                  alt={image.title || "Gallery Image"}
                  style={albumModalStyle.image}
                />
                {image.title && (
                  <div style={albumModalStyle.imageTitle}>
                    <small>{image.title}</small>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Header />
      <div
        style={{
          height: "250px",
          width: "100%",
          backgroundSize: "cover",
          backgroundPosition: "50%",
          backgroundBlendMode: "color",
          backgroundImage: `url(${
            window.location.origin + workshopSeminarData.backgroundImg
          })`,
        }}
      >
        <h1
          style={{
            backgroundColor: `rgba(249, 246, 239, 0.4)`,
            fontWeight: "bold",
            height: "100%",
            color: "#000",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {workshopSeminarData.title}
        </h1>
      </div>

      <Container className="py-5">
        <Row xs={1} md={2} lg={3} className="g-4">
          {albums.map((album) => (
            <Col key={album.id}>
              <Card
                className="h-100 shadow-sm hover:shadow-lg transition-shadow duration-300 cursor-pointer"
                onClick={() => handleAlbumClick(album)}
              >
                <Card.Img
                  variant="top"
                  src={
                    album.thumbnail
                      ? `${BASE_DOC_URL}/${album.thumbnail}`
                      : "placeholder-image.jpg"
                  }
                  style={{ height: "200px", objectFit: "cover" }}
                />
                <Card.Body>
                  <Card.Title>{album.title}</Card.Title>
                  {album.subtitle && (
                    <Card.Subtitle className="mb-2 text-muted">
                      {album.subtitle}
                    </Card.Subtitle>
                  )}
                  <Card.Text className="text-muted">
                    <small>
                      {moment(album.seminarDate).format("MMMM DD, YYYY")}
                      {album.location && ` • ${album.location}`}
                    </small>
                  </Card.Text>
                  <Card.Text>
                    {album.description?.length > 100
                      ? `${album.description.substring(0, 100)}...`
                      : album.description}
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <small className="text-muted">
                    {album.images?.length || 0} Photos
                  </small>
                </Card.Footer>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      {renderAlbumModal()}

      {renderFullScreenModal()}

      <Footer />
    </div>
  );
};

export default WorkshopSeminar;
