import React, { useState } from "react";
import { Styles } from "../award-event/styles/awardEvent";
import Header from "../../components/Header";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/Footer";
import "./styles/event-common.css";

const Event9 = () => {
  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper event-page">
        {/* Header 2 */}
        <Header />

        {/* Breadcroumb */}
        <BreadcrumbBox title="Event" img="16_5.jpg" />
        <Container>
          <Row>
            <Col>
              <a
                href="/news"
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  lineHeight: "40px",
                }}
              >
                Back To News
              </a>
            </Col>
          </Row>
        </Container>

        {/* News Area */}
        <section className="award-page">
          <Container>
            <Row>
              <Col md="12">
                <div style={{ padding: "20px 0" }}>
                  <h2
                    style={{
                      width: "100%",
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    CWIS-FSM Support Cell at WasteSafe 2025 – the 9th
                    International Conference on Sustainable Solutions for Waste
                    Management in Developing Countries
                  </h2>

                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: 500,
                      padding: "20px 0",
                    }}
                  >
                    Date: 22-23 February 2025 at Dhaka, Bangladesh
                  </p>

                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "20px 0",
                    }}
                  >
                    The CWIS FSM Support Cell under the Department of Public
                    Health Engineering (DPHE) actively participated in the
                    prestigious WASTE Safe Conference 2025, held in Dhaka,
                    Bangladesh. The conference, on Sustainable Solutions for
                    Waste Management in Developing Countries, with theme areas
                    of (i) Solid Wastes and Faecal Sludge Management, (ii)
                    Plastics, Medical and EEE Wastes Management and (iii)
                    Integrated Solid Waste and Wastewater Management &
                    Treatment, was held successfully in Dhaka, Bangladesh during
                    22-23 February 2025. The conference was jointly organized by
                    WasteSafe Environmental Limited, Bangladesh; Bauhaus
                    University Weimar, Germany; University of Padova, Italy. The
                    supports are provided by the strategic partners as
                    International Waste Working Group (IWWG), WaterAid
                    Bangladesh and SNV Bangladesh. The conference was sponsored
                    partially by Mutual Trust Bank PLC.
                  </p>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        width: "70%",
                        maxHeight: "550px",
                      }}
                      src={
                        process.env.PUBLIC_URL + `/assets/images/news23/1.png`
                      }
                      alt="img"
                    />
                  </div>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "10px 0",
                    }}
                  >
                    After Inaugural session and Tea break, Keynote session was
                    held presided over by Dr. Abdullah Al Muyeed, CWIS-FSM
                    support cell of DPHE, Bangladesh, in which two papers were
                    delivered. Dr. Tariq bin Yousuf, Solid Waste Management
                    Specialist, former Additional Chief Engineer of DNCC, talked
                    on “Solid waste management in Bangladesh: Critical issues
                    and Paradigm shift”, while Dr. Tanvir Ahmed, Professor,
                    Department of Civil Engineering, Bangladesh University of
                    Engineering & Technology (BUET) delivered 2nd keynote paper
                    entitled as “Achieving safely managed sanitation in
                    Bangladesh: How to maximize benefits from investments in FSM
                    systems in municipalities”.
                  </p>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "10px 0",
                    }}
                  >
                    The CWIS-FSM Support Cell, DPHE showcased its commitment to
                    advancing sanitation and waste management by presenting one
                    research paper titled " City-Wide Inclusive Sanitation
                    (CWIS) in Bangladesh: Advancing Towards SDG 6.2." presented
                    by Mr. Sanjoy Mukherjee, Social Development Expert. The
                    paper highlighted Bangladesh has categorized all 330
                    municipalities into four clusters, mapping their sanitation
                    technology status under the National Action Plan 2021-2030.
                    In alignment with global practices, CWIS Mentor Cities have
                    been identified based on key indicators, providing a roadmap
                    for replication and scaling. In collaboration with CWIS
                    Eco-system partners, five municipalities have adopted
                    long-term CWIS planning, while the Exemplar CWIS Town
                    initiative under a new GoB project serves as a model for
                    nationwide replication. On the other hand To ensure
                    sustained progress, a sector-wide coordination mechanism has
                    been established, with DPHE leading the Safely Managed
                    Sanitation Thematic Group in reviewing and updating the
                    Sector Development Plan (SDP) 2011-2025 in alignment with
                    SDG 6.2.
                  </p>
                  <div style={{ textAlign: "center" }}>
                    <img
                      style={{
                        width: "60%",
                        maxHeight: "450px",
                      }}
                      src={
                        process.env.PUBLIC_URL + `/assets/images/news23/2.png`
                      }
                      alt="img"
                    />
                  </div>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "10px 0",
                    }}
                  >
                    Another Research Paper titled,” Advancing Gender
                    Mainstreaming in Integrated Waste Management: National
                    Initiatives in Bangladesh” presented by Ms. Rifat Binte Jia,
                    Gender Expert from CWIS-FSM Support Cell, DPHE. This paper
                    highlighted, Through the efforts of the CWIS-FSM Support
                    Cell, gender mainstreaming in integrated waste management is
                    advancing by embedding institutional mechanisms such as the
                    Gender & Development Forum (GDF) and the Gender Action Plan
                    (GAP) into sanitation and waste governance. By integrating
                    gender considerations into project design and
                    implementation, the Support Cell is ensuring equitable
                    participation, improved working conditions, and
                    entrepreneurship opportunities for women in the sector.
                    These structured approaches at both national and city levels
                    are driving a transformative shift toward inclusive and
                    sustainable sanitation and waste management, reinforcing the
                    principles of CWIS and paving the way for systemic and
                    long-term change.
                  </p>
                  <div style={{ textAlign: "center" }}>
                    <img
                      style={{
                        width: "60%",
                        maxHeight: "450px",
                      }}
                      src={
                        process.env.PUBLIC_URL + `/assets/images/news23/3.png`
                      }
                      alt="img"
                    />
                  </div>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "10px 0",
                    }}
                  >
                    The presentation was well-received by the international
                    audience, sparking discussions on scalable solutions for
                    low-income countries and the role of inclusive sanitation
                    financing and in improving sanitation infrastructure.
                  </p>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "10px 0",
                    }}
                  >
                    The CWIS FSM Support Cell also engaged in knowledge-sharing
                    sessions, exploring best practices and emerging technologies
                    in waste and fecal sludge management from global experts.
                    The participation of the CWIS FSM Support Cell at WASTE Safe
                    Conference 2025 not only reinforced Bangladesh's leadership
                    in sanitation innovation but also strengthened
                    collaborations with international stakeholders to address
                    the pressing challenges of waste and fecal sludge management
                    in rapidly urbanizing contexts.
                  </p>

                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "12px",
                      color: "black",
                      padding: "10px 0",
                    }}
                  >
                    For more information about the CWIS FSM Support Cell's
                    initiatives and contributions, visit{" "}
                    <a href="https://www.cwisfsmsupportcell.org/">
                      CWIS-FSM Support Cell, DPHE's official website
                    </a>{" "}
                    or contact @ DPHE Bhaban ( 9th Floor ),14 Shaheed Captain
                    Mansur Ali Sarani,Kakrail, Dhaka-1000, Bangladesh.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </Styles>
  );
};

export default Event9;
