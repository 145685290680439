import React, { useState } from "react";
import { Styles } from "../award-event/styles/awardEvent";
import Header from "../../components/Header";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/Footer";
import "./styles/event-common.css";

const Event8 = () => {
  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper event-page">
        {/* Header 2 */}
        <Header />

        {/* Breadcroumb */}
        <BreadcrumbBox title="Event" img="16_5.jpg" />
        <Container>
          <Row>
            <Col>
              <a
                href="/news"
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  lineHeight: "40px",
                }}
              >
                Back To News
              </a>
            </Col>
          </Row>
        </Container>

        {/* News Area */}
        <section className="award-page">
          <Container>
            <Row>
              <Col md="12">
                <div style={{ padding: "20px 0" }}>
                  <h2
                    style={{
                      width: "100%",
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    CWIS -FSM Support Cell at Toilet Conference 2025{" "}
                  </h2>

                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "16px",
                      fontWeight: 500,
                      padding: "20px 0 0",
                      color: "black",
                    }}
                  >
                    Date: February 25-26, 2025
                  </p>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "16px",
                      fontWeight: 500,
                      color: "black",

                      // padding: "20px 0",
                    }}
                  >
                    Venue: InterContinental Dhaka
                  </p>

                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "20px 0",
                    }}
                  >
                    The{" "}
                    <span style={{ fontWeight: "bold" }}>
                      Toilet Conference 2025
                    </span>
                    , themed "The Future of Sanitation," convened on{" "}
                    <span style={{ fontWeight: "bold" }}>
                      Toilet Conference 2025,February 25-26, 2025, in Dhaka,
                      Bangladesh
                    </span>
                    , serving as a prestigious global platform for sanitation
                    experts, policymakers, and innovators. The conference aimed
                    to showcase cutting-edge technologies, pioneering
                    approaches, and advanced management strategies within the
                    sanitation sector.
                  </p>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "20px 0",
                    }}
                  >
                    As a{" "}
                    <span style={{ fontWeight: "bold" }}>
                      Strategic Partner
                    </span>
                    , the{" "}
                    <span style={{ fontWeight: "bold" }}>
                      Department of Public Health Engineering (DPHE){" "}
                    </span>{" "}
                    underscored Bangladesh’s steadfast commitment to advancing{" "}
                    <span style={{ fontWeight: "bold" }}>
                      Water, Sanitation, and Hygiene (WASH) initiatives.
                    </span>
                    The conference featured{" "}
                    <span style={{ fontWeight: "bold" }}>
                      plenary sessions, technical discussions, exhibitions, and
                      technical tours
                    </span>
                    , providing invaluable insights into transformative
                    sanitation innovations. A key highlight was Bangladesh’s
                    remarkable progress in sanitation, including the reduction
                    of{" "}
                    <span style={{ fontWeight: "bold" }}>
                      open defecation from 34% in 1990 to 0% in 2017.
                    </span>
                  </p>

                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "20px 0",
                    }}
                  >
                    A notable contribution was made by{" "}
                    <span style={{ fontWeight: "bold" }}>
                      Mr. Md. Tawhidur Rahaman, Technical Expert from the CWIS
                      FSM Support Cell
                    </span>
                    , who delivered presentation on{" "}
                    <span style={{ fontWeight: "bold" }}>
                      "Service Level Benchmarking for the Evaluation of
                      Integrated Waste Management Plants."
                    </span>{" "}
                    His discourse focused on the{" "}
                    <span style={{ fontWeight: "bold" }}>
                      benchmarking framework, performance indicators, and
                      assessment methodologies
                    </span>{" "}
                    essential for evaluating the operational efficiency of
                    Integrated Waste Management systems. Furthermore, he
                    introduced a{" "}
                    <span style={{ fontWeight: "bold" }}>
                      city-level scoring system
                    </span>
                    , enabling municipalities to systematically measure and
                    enhance their waste treatment infrastructure.
                  </p>

                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "20px 0",
                    }}
                  >
                    In addition to his presentation,{" "}
                    <span style={{ fontWeight: "bold" }}>Mr. Rahaman</span>{" "}
                    participated in
                    <span style={{ fontWeight: "bold" }}>
                      panel discussion on Sanitation Service Models
                    </span>
                    , where he shared critical insights derived from the study
                    on{" "}
                    <span style={{ fontWeight: "bold" }}>
                      business models for inclusive sanitation
                    </span>
                    , published by the{" "}
                    <span style={{ fontWeight: "bold" }}>
                      CWIS FSM Support Cell, DPHE
                    </span>
                    . His discussion delved into{" "}
                    <span style={{ fontWeight: "bold" }}>
                      four typologies of business models practiced in Bangladesh
                    </span>
                    , providing a knowledge-driven reference for policymakers
                    and sanitation professionals striving for sustainable and
                    inclusive urban sanitation solutions.
                  </p>

                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "20px 0",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      The Toilet Conference 2025
                    </span>{" "}
                    reaffirmed its role as a catalyst for global discourse,
                    fostering collaborative efforts toward sustainable
                    sanitation and waste management solutions, ultimately
                    contributing to the realization of{" "}
                    <span style={{ fontWeight: "bold" }}>
                      Sustainable Development Goal 6: Clean Water and Sanitation
                      for All.
                    </span>
                  </p>

                  <Row>
                    <Col md={6} sm={12}>
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{
                            width: "100%",
                            height: "350px",
                          }}
                          src={
                            process.env.PUBLIC_URL +
                            `/assets/images/news22/1.jpg`
                          }
                          alt="award"
                        />
                        <p
                          style={{
                            fontSize: "14px",
                            padding: "10px 20px",
                            textAlign: "justify",
                          }}
                        >
                          Service Level Benchmarking for Evaluating Integrated
                          Waste Management Plants: An Oral Presentation by Md.
                          Tawhidur Rahaman, Technical Expert, CWIS FSM Support
                          Cell, DPHE
                        </p>
                      </div>
                    </Col>
                    <Col md={6} sm={12}>
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{
                            width: "100%",
                            height: "350px",
                          }}
                          src={
                            process.env.PUBLIC_URL +
                            `/assets/images/news22/4.jpg`
                          }
                          alt="award"
                        />
                        <p
                          style={{
                            fontSize: "14px",
                            padding: "10px 20px",
                            textAlign: "justify",
                          }}
                        >
                          All presenters from Oral Presentations Session 01,
                          Themed: Innovative Technology, and the session chair
                          were present.
                        </p>
                      </div>
                    </Col>
                  </Row>

                  <br />
                  <br />
                  <br />
                  <Row>
                    <Col md={6} sm={12}>
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{
                            width: "100%",
                            height: "350px",
                          }}
                          src={
                            process.env.PUBLIC_URL +
                            `/assets/images/news22/2.jpg`
                          }
                          alt="award"
                        />
                        <p
                          style={{
                            fontSize: "14px",
                            padding: "10px 20px",
                            textAlign: "justify",
                          }}
                        >
                          A panel discussion on sanitation service models,
                          focusing on private sector engagement, brought
                          together a distinguished group of experts. The panel
                          comprised Mr. Md. Tawhidur Rahaman, Technical Expert
                          at the CWIS FSM Support Cell, Department of Public
                          Health Engineering (DPHE); Mr. R N Paul, Managing
                          Director of RFL Group; Ms. Vida Dutti, Country
                          Representative of IRC; Mr. Muhammad Siraj, Business
                          Development Specialist at WSUP Bangladesh; and Mr.
                          Sirajus Salekin, Sustainable Finance Specialist at
                          Practical Action, Bangladesh. This gathering aimed to
                          explore innovative sanitation service models and the
                          pivotal role of private sector engagement in enhancing
                          sanitation services.{" "}
                        </p>
                      </div>
                    </Col>
                    <Col md={6} sm={12}>
                      <div style={{ textAlign: "center" }}>
                        <div>
                          <img
                            style={{
                              width: "100%",
                              height: "350px",
                            }}
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/news22/3.jpg`
                            }
                            alt="award"
                          />
                          <p
                            style={{
                              fontSize: "14px",
                              padding: "10px 20px",
                              textAlign: "justify",
                            }}
                          >
                            A group photo with the session moderator, Maya
                            Igarashi Wood, WaterAid's Sanitation Lead, and Guy
                            Norman, also from WaterAid, is presented.{" "}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </Styles>
  );
};

export default Event8;
