import React, { useState } from "react";
import { Styles } from "../award-event/styles/awardEvent";
import Header from "../../components/Header";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/Footer";
import "./styles/event-common.css";

const Event7 = () => {
  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper event-page">
        {/* Header 2 */}
        <Header />

        {/* Breadcroumb */}
        <BreadcrumbBox title="News" img="/news21/1.jpg" />
        <Container>
          <Row>
            <Col>
              <a
                href="/news"
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  lineHeight: "40px",
                }}
              >
                Back To News
              </a>
            </Col>
          </Row>
        </Container>

        {/* News Area */}
        <section className="award-page">
          <Container>
            <Row>
              <Col md="12">
                <div style={{ padding: "20px 0" }}>
                  <h2
                    style={{
                      width: "100%",
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    Training on City-Wide Inclusive Sanitation (CWIS): Focusing on Integrated Waste Management- For Executive Engineers of DPHE
                  </h2>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: 500,
                      padding: "20px 0",
                    }}
                  >
                    11 to January 12, 2025
                  </p>

                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "20px 0",
                    }}
                  >
                    A two-day Training Workshop on CWIS (Citywide Inclusive Sanitation) was successfully conducted from January 11 to January 12, 2025, at Hotel Sea-Gull in Cox’s Bazar organized by Training division, DPHE supported by CWIS-FSM Support Cell, DPHE.  The workshop was attended by 36 participants from Department of Public Health Engineering.
                  </p>


                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "350px",
                    }}
                    src={process.env.PUBLIC_URL + `/assets/images/news21/1.jpg`}
                    alt="thumb"
                  />
                </div>
              </Col>
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "350px",
                    }}
                    src={process.env.PUBLIC_URL + `/assets/images/news21/2.jpg`}
                    alt="thumb"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <p
                  style={{
                    width: "100%",
                    textAlign: "justify",
                    fontSize: "16px",
                    color: "black",
                    padding: "20px 0",
                  }}
                >
                  The inaugural session was chaired by Engr. Md. Jahir Uddin Dewan, Additional Chief Engineer (Water Resource) and podium guests were Mr. Eheteshamul Russel Khan (Additional Chief Engineer-Planning), MD. Shafiqul Hassan, Co-Chair (CWIS-FSM Support Cell), Mohammad Anwar Eusuf (Superintending Engineer-Planning Department, DPHE). The workshop aims to enhancing the participants' understanding and skills in managing sanitation and waste effectively within urban settings. The training aims to empower the engineers to implement more effective, inclusive, and sustainable waste management through integrated systems in their respective areas by transformative technologies, ultimately improving public health and environmental outcomes with sustainable waste management in Bangladesh.
                </p>
                <p
                  style={{
                    width: "100%",
                    textAlign: "justify",
                    fontSize: "16px",
                    color: "black",
                    padding: "20px 0",
                  }}
                >
                  On 11th January, 2025, a day long Treatment Technology field visit was conducted for the participants in Camp-4 and Emergency response Project premises. An over all Integrated waste Management systems, its prospects -Challenges and treatment procedures were vibrantly shown by the Project Director of EMCRP, Mr. Muhammad Abdul Kaium specifically on Plasma Gasification technology with Co-composting through Box-compost mechanism. This was hands on demonstrated field visit and the participants were able to capture the Integrated waste management systems for sustainable manner under CWIS concept.
                </p>
              </Col>

            </Row>


            <Row className="mb-3">
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "350px",
                    }}
                    src={process.env.PUBLIC_URL + `/assets/images/news21/3.jpg`}
                    alt="thumb"
                  />
                </div>
              </Col>
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "350px",
                    }}
                    src={process.env.PUBLIC_URL + `/assets/images/news21/4.jpg`}
                    alt="thumb"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "350px",
                    }}
                    src={process.env.PUBLIC_URL + `/assets/images/news21/5.jpg`}
                    alt="thumb"
                  />
                </div>
              </Col>
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "350px",
                    }}
                    src={process.env.PUBLIC_URL + `/assets/images/news21/6.jpg`}
                    alt="thumb"
                  />
                </div>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "350px",
                    }}
                    src={process.env.PUBLIC_URL + `/assets/images/news21/7.jpg`}
                    alt="thumb"
                  />
                </div>
              </Col>
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "350px",
                    }}
                    src={process.env.PUBLIC_URL + `/assets/images/news21/8.jpg`}
                    alt="thumb"
                  />
                </div>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "350px",
                    }}
                    src={process.env.PUBLIC_URL + `/assets/images/news21/9.jpg`}
                    alt="thumb"
                  />
                </div>
              </Col>
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "350px",
                    }}
                    src={process.env.PUBLIC_URL + `/assets/images/news21/10.jpg`}
                    alt="thumb"
                  />
                </div>
              </Col>
            </Row>


            <Row>
              <Col>
                <p
                  style={{
                    width: "100%",
                    textAlign: "justify",
                    fontSize: "16px",
                    color: "black",
                    padding: "20px 0",
                  }}
                >
                  Visiting the Omni Processor and Plasma Gasification Treatment Plant is significant for DPHE Engineers under the CWIS (City-Wide Inclusive Sanitation) concept in Bangladesh for ; 1. Exposure to Innovative Technologies, 2. Understanding Waste-to-Energy Concepts, 3. Practical Implementation Insights, 4. Sustainability and Environmental Benefits, 6. Capacity Building, 7. Implementation of Localized Solutions. This visit was inevitable for DPHE Engineers as it enhances their understanding of advanced waste management technologies that can significantly contribute to the effective and sustainable management of waste in urban areas of Bangladesh.
                </p>

                <p
                  style={{
                    width: "100%",
                    textAlign: "justify",
                    fontSize: "16px",
                    color: "black",
                    padding: "20px 0",
                  }}
                >
                  On 12th January, 2025 at Hotel Sea-Gull Conference room a day long CWIS Training was held for the participants. Different session had been conducted by the senior Officials as well as Project directors of DPHE.
                </p>
                <p
                  style={{
                    width: "100%",
                    textAlign: "justify",
                    fontSize: "16px",
                    color: "black",
                    padding: "20px 0",
                  }}
                >
                  MD. Shafiqul Hassan, Co-Chair -CWIS-FSM Support Cell, has facilitated an emerging and Ice-breaking session named, ‘Scaling up CWIS in Bangladesh: national Initiatives.’ He elaborately discussed about CWIS chapter, its indicators and possible project impact on DPHE’s different on-going and pipelined projects. Besides, he clearly indicates the importance of Integrated waste management for the upcoming resource scarcities towards sustainable Waste service delivery systems to achieve the SDG 6.2 in Bangladesh.
                </p>
              </Col>
            </Row>


            <Row className="mb-3">
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "60%",
                      maxHeight: "550px",
                    }}
                    src={process.env.PUBLIC_URL + `/assets/images/news21/11.jpg`}
                    alt="thumb"
                  />
                </div>
              </Col>

            </Row>


            <Row>
              <Col>
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "16px",
                    color: "black",
                    padding: "20px 0",
                  }}
                >
                  A session on "Hands-on Learning on SFD (Shit Flow Diagram) Development in Promoting CWIS (City-Wide Inclusive Sanitation)" is to equip participants with the practical skills and knowledge necessary for effectively using SFDs as a tool for assessing and improving sanitation services. This session was conducted by the Technical Expert, Mr. Tawhidur Rahaman, CWIS-FSM Support Cell, DPHE. He enlightened the session through ; 1. Understanding SFD Fundamentals, 2. Practical Application of SFD Development, 3. Data Collection Techniques, 4. Promoting Inclusivity in Sanitation Planning, 5. Integration with CWIS Framework, 6. Formulating Actionable Recommendations. Basically Mr. tawhid emphasized on to empower participants to utilize SFDs effectively as a vital tool for improving sanitation services, promoting better health outcomes, and supporting the principles of inclusivity in urban sanitation
                </p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "550px",
                    }}
                    src={process.env.PUBLIC_URL + `/assets/images/news21/12.jpg`}
                    alt="thumb"
                  />
                </div>
              </Col>
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "550px",
                    }}
                    src={
                      process.env.PUBLIC_URL + `/assets/images/news21/13.jpg`
                    }
                    alt="thumb"
                  />
                </div>
              </Col>
            </Row>


            <Row>
              <Col>
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "16px",
                    color: "black",
                    padding: "20px 0",
                  }}
                >
                  Mr. Sanjoy Mukherjee, Social Development Expert, CWIS-FSM Support Cell, DPHE facilitated a session named ‘Process of Exemplar CWIS Town in Bangladesh’ where he delved to provide participants with insights and practical knowledge about successful implementations of City-Wide Inclusive Sanitation (CWIS) in specific towns-Lakhsmipur, in Bangladesh. This session helps the participants to , 1. Understanding Local Contexts, 2. Learning from Experience, 3. Promoting Collaboration, 4. Identifying Barriers and Solutions, 5. Formulating Actionable Strategies, 6. Encouraging Policy Alignment. This session also aims to empower participants with the knowledge, skills, and confidence necessary to replicate successful CWIS practices in their towns, ultimately leading to better sanitation outcomes and resource utilization through policy development and capacity building improvements across Bangladesh.
                </p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "60%",
                      maxHeight: "550px",
                    }}
                    src={
                      process.env.PUBLIC_URL + `/assets/images/news21/14.jpg`
                    }
                    alt="thumb"
                  />
                </div>
              </Col>

            </Row>
            <Row>
              <Col>
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "16px",
                    color: "black",
                    padding: "20px 0",
                  }}
                >
                  Following the sessions, Mr. MD. Saifur Rahman, Project Director GOB-UNICEF Project delivered an informative and discussive session on ‘IRF-NAP of FSM towards achieving SDG 6 from 2016 to 2030 focusing CWIS in Bangladesh. This discussion focused on SDG’s performance 2024 in Bangladesh where SDG indicators led by different Ministries specifically LGD highlighted. The presenter also demonstrates the Safely managed drinking water/Sanitation/ Handwashing/ wastewater safely treated scenarios during this session.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "60%",
                      maxHeight: "550px",
                    }}
                    src={
                      process.env.PUBLIC_URL + `/assets/images/news21/15.jpg`
                    }
                    alt="thumb"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "16px",
                    color: "black",
                    padding: "20px 0",
                  }}
                >
                  Dr. Abdullah Al-Muyeed, Chief Operating Officer, CWIS-FSM Support Cell, DPHE facilitated an interactive session on ‘Integrated Waste management and CWIS in Bangladesh’. This session aimed to equip participants with the knowledge and skills necessary to effectively integrate waste management practices, treatment technology within the framework of CWIS. Main key points of the session was, 1. Understanding Integrated Waste Management (IWM), 2. Linking Waste Management and CWIS using transformative technologies, 3. Contextualize IWM strategies within their local settings, 4. Formulating Action Plans, 5. Sustainable treatment Practices and Innovations based on current field visit. The training session aimed to empower participants to effectively integrate waste management strategies into CWIS frameworks, leading to improved sanitation services, enhanced public health, and greater environmental sustainability in urban areas of Bangladesh.
                </p>
              </Col>
            </Row>

            <Row>
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "550px",
                    }}
                    src={
                      process.env.PUBLIC_URL + `/assets/images/news21/16.jpg`
                    }
                    alt="thumb"
                  />
                </div>
              </Col>
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "550px",
                    }}
                    src={
                      process.env.PUBLIC_URL + `/assets/images/news21/17.jpg`
                    }
                    alt="thumb"
                  />
                </div>
              </Col>

            </Row>
            <Row>
              <Col>
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "16px",
                    color: "black",
                    padding: "20px 0",
                  }}
                >
                  Md. Nazrul Islam Miah, Project director, 32 Town Project, DPHE has delivered his timely and insightful discussion to the participants during conducting a session titled on ‘ Waste management Challenges and Way Forwards’. He focused on identifying the key issues related to waste management in the respective project towns and exploring effective strategies, challenges and opportunities for improvement. Interactive discussions happened on the critical next steps and future initiatives that can be undertaken to overcome current challenges and improve Integrated waste management systems with sustainable technologies in Bangladesh.
                  By focusing on these components, the discussion aimed to initiate productive dialogue among stakeholders, encourage innovative technological thinking, and develop actionable solutions to enhance Integrated waste management practices in Bangladesh.

                </p>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "60%",
                      maxHeight: "550px",
                    }}
                    src={
                      process.env.PUBLIC_URL + `/assets/images/news21/18.png`
                    }
                    alt="thumb"
                  />
                </div>
              </Col>

            </Row>

            <Row>
              <Col>
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "16px",
                    color: "black",
                    padding: "20px 0",
                  }}
                >
                  After lunch break, Rifat Binte Jia, Gender Expert-CWIS-FSM Support Cell, DPHE conducted a session on ‘ Addressing Gender and Equity in promoting CWIS’ where she captured that gender considerations and equity principles are integrated into sanitation planning and implementation processes. It includes, 1. Raising Awareness of Gender Issues, 2. Promoting Inclusivity, 3. Identifying Barriers, 4. Integrating Gender Perspectives in Inclusive Sanitation, 5. Developing Action Plans, 6. Developing Action Plans, and 7. Advocacy for Policy Change.
                  The training session aimed to enhance understanding and implementation of gender and equity principles in CWIS, ultimately leading to improved sanitation outcomes that are equitable, inclusive, and reflective of the diverse needs of intervene project towns.


                </p>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "60%",
                      maxHeight: "550px",
                    }}
                    src={
                      process.env.PUBLIC_URL + `/assets/images/news21/19.png`
                    }
                    alt="thumb"
                  />
                </div>
              </Col>

            </Row>

            <Row>
              <Col>
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "16px",
                    color: "black",
                    padding: "20px 0",
                  }}
                >
                  The last session was ‘FSM Operational and Business Models in Bangladesh’ facilitated by Mr. Monzur Morshed, Business Expert, CWIS-FSM Support Cell, DPHE. He presented the existing scenarios of FSM service delivery systems in Bangladesh. This session represents Operational Models for Effective FSM in Bangladesh, Business Models for Sustainable FSM, Cost-Benefit Analysis of Integrated Waste management(IWM) Services with different treatment technologies, and Financing IWM Initiatives in Bangladesh. to provide participants with a holistic understanding of FSM operational and business models, equipping them with the knowledge needed to develop, implement, and improve FSM practices in their respective regions in Bangladesh. This discussion was to provide participants with a holistic understanding of FSM towards IWM an operational and business models, equipping them with the knowledge needed to develop, implement, and improve IWM practices in their respective regions in Bangladesh.

                </p>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "60%",
                      maxHeight: "550px",
                    }}
                    src={
                      process.env.PUBLIC_URL + `/assets/images/news21/20.jpg`
                    }
                    alt="thumb"
                  />
                </div>
              </Col>

            </Row>

            <Row>
              <Col>
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "16px",
                    color: "black",
                    padding: "20px 0",
                  }}
                >
                  At Closing points of the day long training workshop, Ms. Ruksana Parvin, Executive Engineer, Training Division, DPHE paid vote of thanks to the participants for their high energetic participation to the Two days CWIS Training Workshop.

                </p>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "60%",
                      maxHeight: "550px",
                    }}
                    src={
                      process.env.PUBLIC_URL + `/assets/images/news21/21.jpg`
                    }
                    alt="thumb"
                  />
                </div>
              </Col>

            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </Styles>
  );
};

export default Event7;
