import React, { useState } from "react";
import { Styles } from "../award-event/styles/awardEvent";
import Header from "../../components/Header";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/Footer";
import "./styles/event-common.css";

const Event10 = () => {
  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper event-page">
        {/* Header 2 */}
        <Header />

        {/* Breadcroumb */}
        <BreadcrumbBox title="Event" img="16_5.jpeg" />
        <Container>
          <Row>
            <Col>
              <a
                href="/news"
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  lineHeight: "40px",
                }}
              >
                Back To News
              </a>
            </Col>
          </Row>
        </Container>

        {/* News Area */}
        <section className="award-page">
          <Container>
            <Row>
              <Col md="12">
                <div style={{ padding: "20px 0" }}>
                  <h2
                    style={{
                      width: "100%",
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    CWIS-FSM Support Cell Showcases Bangladesh’s Sanitation
                    Innovations at IWA Water Reuse Conference, Cape Town, South
                    Africa
                  </h2>

                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: 500,
                      padding: "20px 0",
                    }}
                  >
                    Date: March 16-20, 2025
                  </p>

                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "20px 0",
                    }}
                  >
                    The CWIS-FSM Support Cell of the Department of Public Health
                    Engineering (DPHE), Bangladesh participated at the{" "}
                    <a
                      href="https://iwareuse2025.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      14th IWA International Conference on Water Reclamation and
                      Reuse
                    </a>
                    , held in Cape Town from March 16-20, 2025. Two technical
                    papers were selected for presentation, showcasing
                    Bangladesh's commitment to innovative solutions in faecal
                    sludge and solid waste management through Integrated Waste
                    Management (IWM).
                  </p>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        width: "70%",
                        maxHeight: "550px",
                      }}
                      src={
                        process.env.PUBLIC_URL + `/assets/images/news24/1.jpeg`
                      }
                      alt="img"
                    />
                  </div>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "10px 0",
                    }}
                  >
                    <br />
                    The CWIS-FSM Support Cell presented two critical initiatives
                    addressing these challenges: <br />
                    <br />
                    <strong>
                      1. Technology Mapping of Faecal Sludge and Solid Waste
                      Treatment Systems in Bangladesh{" "}
                    </strong>{" "}
                    <br />
                    <br />
                    This study employed a mixed-method approach to
                    comprehensively map the faecal sludge and solid waste
                    treatment technologies currently in use across Bangladesh.
                    The study, authored by A. Al-Muyeed, (Co-authors: T.
                    Rahaman, T. Ahmed, and S. Hassan.) The research revealed
                    that while Bangladesh has 38 sanitary landfill sites and 50
                    faecal sludge treatment plants (FSTP), only 19 of these
                    plants are currently operational. A major challenge lies in
                    the effective management of dry sludge (biosolids) produced
                    by these FSTPs. While some municipalities are successfully
                    implementing co-composting practices to improve compost
                    quality, many plants lack this crucial facility. To combat
                    this, the Department of Public Health Engineering is
                    actively implementing Integrated Waste Management (IWM)
                    plants. These facilities can treat both faecal sludge and
                    solid waste in a single location using advanced
                    technologies, such as plasma gasifiers, and channel
                    composting. Despite land constraints and maintenance
                    challenges, the IWM concept is being scaled up, with
                    numerous plants under construction. Expanding the Integrated
                    Waste Management system is crucial for achieving City Wide
                    Inclusive Sanitation (CWIS), effective resource recovery,
                    and a sustainable, zero-waste future for the 330
                    municipalities and 12 city corporations in Bangladesh."
                  </p>

                  <div style={{ textAlign: "center" }}>
                    <img
                      style={{
                        width: "40%",
                        maxHeight: "550px",
                      }}
                      src={
                        process.env.PUBLIC_URL + `/assets/images/news24/2.jpeg`
                      }
                      alt="img"
                    />
                  </div>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "10px 0",
                    }}
                  >
                    <br />
                    <strong>
                      2. Service Level Benchmark for Measuring the Efficiency of
                      Integrated Waste Management Plants
                    </strong>
                    <br />
                    <br />
                    Mr. Md. Tawhidur Rahaman, Technical Expert at the CWIS-FSM
                    Support Cell, presented this paper focused on developing a
                    Service Level Benchmark (SLB) to measure the efficiency of
                    Integrated Waste Management (IWM) plants. (Co-authors: A.
                    Al-Muyeed, T. Ahmed, and S. Hassan) With rapid urbanization
                    and increasing waste generation, the Government of
                    Bangladesh has prioritized urban sanitation and approved the
                    National Action Plan 2021-2030 for Faecal Sludge Management.
                    The establishment of IWM plants that treat both faecal
                    sludge and solid waste at the same site is a key component
                    of this plan. As Bangladesh expands its network of FSTPs and
                    IWM plants, a standardized set of performance indicators is
                    essential to ensure efficient management. The newly
                    introduced SLB framework provides a set of minimum standards
                    for measuring the efficiency of FSTPs and IWM plants,
                    aligning with existing government regulations. This SLB
                    framework will contribute to rationalizing decision-making,
                    strengthening accountability, promoting transparency, and
                    prioritizing development activities in line with the
                    City-Wide Inclusive Sanitation (CWIS) principle. This
                    structured reporting system facilitates data-driven
                    decision-making, leading to timely improvements in waste
                    management practices while ensuring environmental and public
                    health benefits.
                  </p>
                  <div style={{ textAlign: "center" }}>
                    <img
                      style={{
                        width: "60%",
                        maxHeight: "450px",
                      }}
                      src={
                        process.env.PUBLIC_URL + `/assets/images/news24/3.jpeg`
                      }
                      alt="img"
                    />
                  </div>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "10px 0",
                    }}
                  >
                    <br />
                    <b>CWIS-FSM’s Leadership in Global Discussions</b> <br />
                    <br />
                    Dr. Abdullah Al Muyeed, Chief Operating Officer of the
                    CWIS-FSM Support Cell chaired a session focusing on "Water
                    Reuse in Developing Countries." The session offered key
                    perspectives on innovative, appropriate, and community-based
                    approaches essential for tackling water challenges in
                    developing regions.
                  </p>
                  <Row>
                    <Col>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{
                            width: "100%",
                            maxHeight: "350px",
                          }}
                          src={
                            process.env.PUBLIC_URL +
                            `/assets/images/news24/4.jpeg`
                          }
                          alt="thumb"
                        />
                      </div>
                    </Col>
                    <Col>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{
                            width: "100%",
                            maxHeight: "350px",
                          }}
                          src={
                            process.env.PUBLIC_URL +
                            `/assets/images/news24/5.jpeg`
                          }
                          alt="thumb"
                        />
                      </div>
                    </Col>
                  </Row>

                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "10px 0",
                    }}
                  >
                    The session featured presentations on three important
                    papers: <br />
                    <br />
                    <h6 style={{ fontWeight: "bold" }}>
                      Low-Tech Trickling Filters
                    </h6>{" "}
                    <br />
                    Dr. Krauss Manuel presented "Decentral Wastewater Treatment
                    with Low-Tech Trickling Filters in Different Climates in
                    Peru and Cameroon." This presentation focused on the
                    efficacy of cost-effective, low-tech trickling filter
                    systems for wastewater treatment across diverse climatic
                    conditions in Peru and Cameroon. <br />
                    <br />
                    <h6 style={{ fontWeight: "bold" }}>
                      Health Risk Management
                    </h6>{" "}
                    <br />
                    Mr. David Galibourg shared insights from "Health Risk
                    Management Along Urban Wastewater-Irrigated Vegetable Value
                    Chains of LMICs: Ensuring That Opportunity, Capability and
                    Motivation Needs Are Identified." The presentation
                    highlighted the need to address opportunity, capability, and
                    motivation needs when managing health risks. <br />
                    <br />
                    <h6 style={{ fontWeight: "bold" }}>
                      Community-Managed Sanitation
                    </h6>{" "}
                    <br />
                    Mr. Md Shakhawat Hossain discussed "Community-Managed
                    Combined Containment System to Enhance Safely Managed
                    Sanitation Coverage at Low-Income Communities." The paper
                    highlighted community-managed approaches to improve
                    sanitation coverage in low-income areas. The session
                    included vibrant discussions on the practical considerations
                    and scalability of these strategies. Dr. Al Muyeed
                    emphasized the necessity of adopting sustainable,
                    community-focused solutions that address both water scarcity
                    and public health in developing nations. The insights shared
                    promise to contribute significantly to more resilient and
                    equitable water management practices worldwide.
                  </p>

                  <div style={{ textAlign: "center" }}>
                    <img
                      style={{
                        width: "60%",
                        maxHeight: "450px",
                      }}
                      src={
                        process.env.PUBLIC_URL + `/assets/images/news24/6.jpeg`
                      }
                      alt="img"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </Styles>
  );
};

export default Event10;
